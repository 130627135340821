import { animateTo, stopAnimations } from '@horizon/common/helpers';
import { HznIconInfoCircle } from '@horizon/icons/individual';
import { HznInline } from '@horizon/inline';
import { LitElement, html } from '@horizon/base';
import { property, query } from '@horizon/base/decorators';
import { ScopedElementsMap, ScopedElementsMixin } from '@open-wc/scoped-elements/lit-element.js';
import { SlPopup } from '@horizon/common/components';
import TooltipStyles from './tooltip.css.js';

/**
 *
 * @tag hzn-tooltip
 * @tagname hzn-tooltip
 * @summary A tooltip is a messaging component that shows a small piece of extra,
 *          non-critical, contextual information on hover of an element.
 */

export class HznTooltip extends ScopedElementsMixin(LitElement) {

  #hoverTimeout!: number;

  /**
   * @private
   */
  static styles = [TooltipStyles];

  /**
   * @private
   */
  static get scopedElements(): ScopedElementsMap {
    return {
      'hzn-icon-info-circle': HznIconInfoCircle,
      'hzn-inline': HznInline,
      'hzn-popup': SlPopup,
    };
  }

  /**
   * @private
   */
  @query('.tooltip-body') body!: HTMLElement;

  /**
   * @private
   */
  @query('.tooltip') popup!: SlPopup;

  /**
   * @private
   */
  @query('.tooltip-trigger') trigger!: HTMLSlotElement;

  /**
   * The tooltip content. Accepts only plain text.
   */
  @property({ type: String }) content = '';

  /**
   * When true, the tooltip will be hoisted to the body element.
   * This can be used to ensure the tooltip will be displayed when contained
   * by an ancestor with `overflow: auto | clip | hidden | scroll`.
   */
  @property({ type: Boolean }) hoist = false;

  /**
   * The icon to display as the tooltip trigger.
   */
  @property({ type: String }) icon = 'outline';

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this.icon === 'none') {
      this.removeEventListener('blur', this.#handleBlur, true);
      this.removeEventListener('focus', this.#handleFocus, true);
      this.removeEventListener('mouseleave', this.#handleMouseOut);
      this.removeEventListener('mouseenter', this.#handleMouseOver);
    } else {
      this.trigger.removeEventListener('blur', this.#handleBlur, true);
      this.trigger.removeEventListener('focus', this.#handleFocus, true);
      this.removeEventListener('mouseleave', this.#handleMouseOut);
      this.trigger.removeEventListener('mouseenter', this.#handleMouseOver);
    }

    document.removeEventListener('keydown', this.#handleDocumentKeyDown);
  }

  firstUpdated(){
    if (this.icon === 'none') {
      this.addEventListener('blur', this.#handleBlur, true);
      this.addEventListener('focus', this.#handleFocus, true);
      this.addEventListener('mouseleave', this.#handleMouseOut);
      this.addEventListener('mouseenter', this.#handleMouseOver);
    } else {
      this.trigger.addEventListener('blur', this.#handleBlur);
      this.trigger.addEventListener('focus', this.#handleFocus, true);
      this.addEventListener('mouseleave', this.#handleMouseOut);
      this.trigger.addEventListener('mouseenter', this.#handleMouseOver);
    }
  }

  /**
   * @private
   */
  #handleBlur = () => {
    clearTimeout(this.#hoverTimeout);
    this.#hoverTimeout = window.setTimeout(() => this.#hide(), 50);
  };

  /**
   * @private
   */
  #handleFocus = () => {
    clearTimeout(this.#hoverTimeout);
    this.#hoverTimeout = window.setTimeout(() => this.#show(), 50);
  };

  /**
   * @private
   */
  #handleDocumentKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      this.#hide();
    }
  };

  /**
   * @private
   */
  #handleMouseOver = (e: MouseEvent) => {
    if ((e.target as HTMLInputElement).disabled) return;

    clearTimeout(this.#hoverTimeout);
    this.#hoverTimeout = window.setTimeout(() => this.#show(), 50);
  };

  /**
   * @private
   */
  #handleMouseOut = () => {
    clearTimeout(this.#hoverTimeout);
    this.#hoverTimeout = window.setTimeout(() => this.#hide(), 50);
  };

  /**
   * @private
   */
  #handleDocumentClick = (event: MouseEvent) => {
    // only close the toggletip if the click came from outside the toggletip
    // interactive elements inside toggletips will need to manually call
    // hide() to close the toggletip if needed
    if(!event.composedPath().includes(this)) {
      this.#hide();
    }
  };

  /**
   * @private
   */
  async #show() {

    if (this.content.trim() === '') return;

    document.addEventListener('keydown', this.#handleDocumentKeyDown);

    await stopAnimations(this.body);

    this.body.hidden = false;
    this.popup.active = true;

    await animateTo(this.popup.popup, [
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1 }
    ], { duration: 350, easing: 'ease' });

    this.body.setAttribute('aria-live', 'polite');

    this.popup.reposition();

    // add the document click listener after the popup has been shown
    // so that this listener doesnt happen so fast that the original click prevents
    // the first show
    document.addEventListener('click', this.#handleDocumentClick);

  }

  /**
   * @private
   */
  async #hide() {

    document.removeEventListener('keydown', this.#handleDocumentKeyDown);
    document.removeEventListener('click', this.#handleDocumentClick);

    await stopAnimations(this.body);

    await animateTo(this.popup.popup, [
      { opacity: 1, scale: 1 },
      { opacity: 0, scale: 0.8 }
    ], { duration: 150, easing: 'ease' });

    this.body.setAttribute('aria-live', 'off');

    this.popup.active = false;
    this.body.hidden = true;
  }

  /**
   * @private
   */
  #renderTooltipBody() {
    return html`<div class="tooltip-body" role="tooltip">
      <div class="tooltip-content">${this.content}</div>
    </div>`;
  }

  /**
   * @private
   */
  #renderWithIcon() {
    const iconVariant = this.icon === 'fill' || this.icon === 'filled' ? 'filled' : 'stroked';

    return html`<hzn-inline align-y="center">
      <slot></slot>
      <hzn-popup
        arrow
        class="tooltip"
        distance=8
        flip
        hover-bridge
        placement="top"
        shift
        strategy="${this.hoist ? 'fixed' : 'absolute'}"
      >
        <span slot="anchor" aria-describedby="tooltip" class="tooltip-trigger" tabindex="0">
          <hzn-icon-info-circle variant="${iconVariant}"></hzn-icon-info-circle>
        </span>
        ${this.#renderTooltipBody()}
      </hzn-popup>
    </hzn-inline>`
  }

  /**
   * @private
   */
  #renderWithoutIcon() {
    return html`
      <hzn-popup
        arrow
        class="tooltip"
        distance=8
        flip
        hover-bridge
        placement="top"
        shift
        strategy="${this.hoist ? 'fixed' : 'absolute'}"
      >
        <slot slot="anchor" aria-describedby="tooltip" class="tooltip-trigger"></slot>
        ${this.#renderTooltipBody()}
      </hzn-popup>`
  }

  render() {
    return html`${this.icon === 'none'
      ? this.#renderWithoutIcon()
      : this.#renderWithIcon()
      }`;
  }

}
