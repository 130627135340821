
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-copy
 * @tagname hzn-icon-copy
 * @summary An individual icon for Horizon
 */
export class HznIconCopy extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 2.79001H19C20.49 2.79001 21.71 4.01001 21.71 5.50001V13.5C21.71 14.99 20.49 16.21 19 16.21H18C17.61 16.21 17.29 15.89 17.29 15.5C17.29 15.11 17.61 14.79 18 14.79H19C19.71 14.79 20.29 14.21 20.29 13.5V5.50001C20.29 4.79001 19.71 4.21001 19 4.21001H11C10.29 4.21001 9.71004 4.79001 9.71004 5.50001C9.71004 5.89001 9.39004 6.21001 9.00004 6.21001C8.61004 6.21001 8.29004 5.89001 8.29004 5.50001C8.29004 4.01001 9.51004 2.79001 11 2.79001ZM2.29004 10C2.29004 8.51001 3.51004 7.29001 5.00004 7.29001H13C14.49 7.29001 15.71 8.51001 15.71 10V18C15.71 19.49 14.49 20.71 13 20.71H5.00004C3.51004 20.71 2.29004 19.49 2.29004 18V10ZM13 19.29C13.71 19.29 14.29 18.71 14.29 18V10C14.29 9.29001 13.71 8.71001 13 8.71001H5.00004C4.29004 8.71001 3.71004 9.29001 3.71004 10V18C3.71004 18.71 4.29004 19.29 5.00004 19.29H13Z" fill="currentColor" class="stroked"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M11 2.79001H19C20.49 2.79001 21.71 4.01001 21.71 5.50001V13.5C21.71 14.99 20.49 16.21 19 16.21H17.1V10C17.1 7.74001 15.26 5.90001 13 5.90001H8.29004V5.50001C8.29004 4.01001 9.51004 2.79001 11 2.79001ZM5.00004 7.29001H13C14.4967 7.29001 15.71 8.50332 15.71 10V18C15.71 19.4967 14.4967 20.71 13 20.71H5.00004C3.50335 20.71 2.29004 19.4967 2.29004 18V10C2.29004 8.50332 3.50335 7.29001 5.00004 7.29001Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
