import { HznTooltip } from '@horizon/tooltip';
import { HznIconCopy } from '@horizon/icons/individual';
import { LitElement, html, ReactiveElement } from '@horizon/base';
import { ScopedElementsMap, ScopedElementsMixin } from '@open-wc/scoped-elements/lit-element.js';
import { property, query, state } from '@horizon/base/decorators';
import { HznCopyButtonTone } from '../types.js';
import { classMap } from '@horizon/base/directives.js';
import CopyButtonStyles from './copy-button.css.js';

/**
 *
 * @tag hzn-copy-button
 * @tagname hzn-copy-button
 * @summary A button that copies text to the clipboard when clicked. Conveys status through a tooltip.
 *
 * @dependency hzn-tooltip
 */

export class HznCopyButton extends (ScopedElementsMixin(LitElement) as typeof ReactiveElement) {
  static styles = [CopyButtonStyles];

  get #copyLabel () {
    return this.copyLabel || 'Copy';
  }

  /**
   * @private
   */
  static get scopedElements(): ScopedElementsMap {
    return {
      'hzn-tooltip': HznTooltip,
      'hzn-icon-copy': HznIconCopy
    };
  }

  /**
   * @private
   */
  @state() isCopying = false;

  /**
   * @private
   */
  @state() status: 'rest' | 'success' | 'error' = 'rest';

  /**
   * The internal native button
   */
  @query('.copy-button') innerButton!: HTMLButtonElement;

  /**
   * @private
   * The internal tooltip
   */
  @query('hzn-tooltip') tooltip!: HznTooltip;

  /**
   * A custom label to display in the tooltip.
   * Default is 'Copy'.
   */
  @property({ type: String, attribute: 'copy-label', reflect: false }) copyLabel = '';

  /** Disables the copy button. */
  @property({ type: Boolean }) disabled = false;

  /**
   * When true, the tooltip will be hoisted to the body element.
   * This can be used to ensure the tooltip will be displayed when contained
   * by an ancestor with `overflow: auto | clip | hidden | scroll`.
   */
  @property({ type: Boolean }) hoist = false;

  /** The text value to copy. */
  @property({ type: String }) value = '';

  /**
    * An id that references an element in the same document from which data will be copied.
    * If both this and `value` are present, the value in `from` will take precedence.
    *
    * By default, the target element's `textContent` will be copied.
    * To copy an attribute, append the attribute name wrapped in square brackets, e.g. `from="el[value]"`.
    * To copy a property, append a dot and the property name, e.g. `from="el.value"`.
    */
  @property() from = '';

  /**
   * Sets the tone for the button. Allowed values are `neutral | interactive`. Default is `neutral`.
   * @playroomValues {'neutral' | 'interactive'}
   */
  @property({ type: String }) tone: HznCopyButtonTone = 'neutral';

  /**
   * @private
   */
  showStatus(status: 'success' | 'error') {
    const successLabel = 'Copied';
    const errorLabel = 'Error';

    this.tooltip.content = status === 'success' ? successLabel : errorLabel;

    this.status = status;

    // After a brief delay, restore the original state
    setTimeout(() => {
      this.status = 'rest';
      this.tooltip.content = this.#copyLabel;
      this.isCopying = false;
    }, 1350);
  }

  /**
   * @private
   */
  async #handleCopy() {
    if (this.disabled || this.isCopying) {
      return;
    }
    this.isCopying = true;

    // Copy the value by default
    let valueToCopy = this.value;

    // If an element is specified, copy from that instead
    if (this.from) {
      const root = this.getRootNode() as ShadowRoot | Document;

      // Simple way to parse ids, properties, and attributes
      const isProperty = this.from.includes('.');
      const isAttribute = this.from.includes('[') && this.from.includes(']');
      let id = this.from;
      let field = '';

      if (isProperty) {
        // Split at the dot
        [id, field] = this.from.trim().split('.');
      } else if (isAttribute) {
        // Trim the ] and split at the [
        [id, field] = this.from.trim().replace(/\]$/, '').split('[');
      }

      // Locate the target element by id
      const target = 'getElementById' in root ? root.getElementById(id) : null;

      if (target) {
        if (isAttribute) {
          valueToCopy = target.getAttribute(field) || '';
        } else if (isProperty) {
          // @ts-expect-error
          valueToCopy = target[field] || '';
        } else {
          valueToCopy = target.textContent || '';
        }
      } else {
        // No target
        this.showStatus('error');
      }
    }

    // No value
    if (!valueToCopy) {
      this.showStatus('error');
    } else {
      try {
        await navigator.clipboard.writeText(valueToCopy);
        this.showStatus('success');
      } catch (error) {
        // Rejected by browser
        this.showStatus('error');
      }
    }
  }

  render() {
    // using `inert` to prevent mouseover tooltip behavior when copy-button is disabled.
    // TODO: implement disabled in tooltip and update here.
    return html`
      <hzn-tooltip
        ?inert=${this.disabled}
        ?hoist=${this.hoist}
        content=${this.#copyLabel}
        icon="none"
        >
        <button
          @click=${this.#handleCopy}
          aria-label=${this.#copyLabel}
          ?disabled=${this.disabled}
          class=${classMap({
            'copy-button': true,
            'is-disabled': Boolean(this.disabled),
            'is-tone-neutral': this.tone !== 'interactive',
            'is-tone-interactive': this.tone === 'interactive',
          })}
        >
          <hzn-icon-copy></hzn-icon-copy>
        </button>
      </hzn-tooltip>
    `;
  }
}
